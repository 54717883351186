import { render, staticRenderFns } from "./PromoOnly.vue?vue&type=template&id=19f04e00&scoped=true"
import script from "./PromoOnly.vue?vue&type=script&lang=js"
export * from "./PromoOnly.vue?vue&type=script&lang=js"
import style0 from "./PromoOnly.vue?vue&type=style&index=0&id=19f04e00&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f04e00",
  null
  
)

export default component.exports